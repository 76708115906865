<template>
  <div>
    <div class="login" v-if="mostral">
      <v-container>
        <v-row class="text-center primary--text">
          <v-col cols="12">
            <p class="text-h5" style="font-family: Kelly Slab !important">
              <strong style="font-family: Kelly Slab !important"
                >Aproveite a Oportunidade</strong
              >
              de negociar seu débito.
            </p>

            <p class="text-h5" style="font-family: Kelly Slab !important">
              Informe seu CPF/CNPJ para ver o boleto
            </p>
          </v-col>
        </v-row>
      </v-container>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <v-card class="elevation-12">
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title class="text-center"></v-toolbar-title>

                <v-spacer></v-spacer>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field
                    label="Entre com seu CPF/CNPJ"
                    v-model="login"
                    prepend-icon="mdi-account"
                    type="text"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" block @click="enviar">Ver Oferta</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-container class="d-flex justify-center" v-if="mostrab">
      <v-row>
        <v-col xs="12">
          <p class="text-h3">Aguarde...</p>
          <v-progress-linear indeterminate color="cyan"></v-progress-linear>
          <br />
          <v-progress-linear indeterminate color="cyan"></v-progress-linear>
          <br />
          <v-progress-linear indeterminate color="cyan"></v-progress-linear>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { api } from "@/conf/api";
export default {
  name: "loading",
  data() {
    return {
      mostral:true,
      mostrab: false,
      cpf: "",
      contrato: "",
      tipo: "",
      login: "",
    };
  },
  async beforeMount() {
    this.$store.dispatch("setMostraBotoes", { imprimir: false, voltar: false });
  },
  async mounted() {
    
    this.cpf = this.$route.params.cpf;
    this.contrato = this.$route.params.contrato;
    this.tipo = this.$route.params.tipo;
    //console.log("tipo", this.tipo, this.tipo.toLowerCase());
    // console.log(cpf,contrato,tipo)
    /*
    try {
      let resposta = await api.post("bradesco/boleto/pegarboleto", {
        cpf,
        contrato,
        tipo,
      });
      // console.log('resp',resposta.data)
      if (!resposta.data[0].boleto) throw new Error("internoerro");
      let meuboleto = resposta.data[0];

      let minhasFaturas = meuboleto.boleto.dadosnegoc.faturas;
      let faturasboleto = [];
      minhasFaturas.forEach((e) => {
        let numdoc = e.pnnumdoc;
        let venc = e.pndtvencto.substring(0, 10);
        let valor = e.pnvalor;
        faturasboleto.push({ numdoc, venc, valor });
      });

      let boleto = {
        databoleto: meuboleto.datanegoc.substring(0, 10),
        nome: meuboleto.boleto.dadoscli.nome.trim(),
        cpf: meuboleto.cpf.trim(),
        contrato: meuboleto.contrato.trim(),
        valor: meuboleto.valor,
        venc: meuboleto.venc.substring(0, 10),
        faturas: faturasboleto,
        cb: meuboleto.codbarras.trim(),
        ld: meuboleto.linhadig.trim(),
        nossonum: meuboleto.nossonumcomdig.trim(),
        nossonum2: meuboleto.nossonumero.trim(),
        endereco: meuboleto.boleto.dadoscli.end.end.trim(),
        parc: meuboleto.parcela,
        totalparc: meuboleto.totalparcela,
        boleto_ori: meuboleto,
      };

      //console.log(boleto)

      this.$store.dispatch("insereBolViasat", boleto);
      this.$router.push({ name: "BolViaSat2" });
    } catch (e) {
      console.log("err", e.message);
      this.$router.push({ name: "ErroPage" });
    }
    */
  },
  methods: {
    async enviar() {
      this.$store.commit("zeraMessage");
      let meu = this.login.trim()
      if(this.cpf != meu){
        this.$store.commit("showMessage", {
          text: "CPF/CNPJ divergente!",
          color: "warning",
          show: true,
        });
        this.login = "";
        return;
      }
      this.mostral=false
      this.mostrab= true
      await api.post("Logs/inserecpflog",{cpf: this.cpf})
      let datat = {
        cpf: this.cpf,
        contrato: this.contrato,
        tipo: this.tipo
      }
      try {
      let resposta = await api.post("bradesco/boleto/pegarboleto", datat);
      console.log('resp',resposta.data)
      if (!resposta.data[0].boleto) throw new Error("internoerro");
      let meuboleto = resposta.data[0];

      let minhasFaturas = meuboleto.boleto.dadosnegoc.faturas;
      let faturasboleto = [];
      minhasFaturas.forEach((e) => {
        let numdoc = e.pnnumdoc;
        let venc = e.pndtvencto.substring(0, 10);
        let valor = e.pnvalor;
        faturasboleto.push({ numdoc, venc, valor });
      });

      let boleto = {
        databoleto: meuboleto.datanegoc.substring(0, 10),
        nome: meuboleto.boleto.dadoscli.nome.trim(),
        cpf: meuboleto.cpf.trim(),
        contrato: meuboleto.contrato.trim(),
        valor: meuboleto.valor,
        venc: meuboleto.venc.substring(0, 10),
        faturas: faturasboleto,
        cb: meuboleto.codbarras.trim(),
        ld: meuboleto.linhadig.trim(),
        nossonum: meuboleto.nossonumcomdig.trim(),
        nossonum2: meuboleto.nossonumero.trim(),
        endereco: meuboleto.boleto.dadoscli.end.end.trim(),
        parc: meuboleto.parcela,
        totalparc: meuboleto.totalparcela,
        boleto_ori: meuboleto,
        
      };

      //console.log(boleto)

      this.$store.dispatch("insereBolViasat", boleto);
      this.$router.push({ name: "BolViaSat2" });
    } catch (e) {
      console.log("err", e.message);
      this.$router.push({ name: "ErroPage" });
    }



    },

  },
};
</script>
